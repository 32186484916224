// Changing these values will break the flow of leads going into Salesforce (via shopify/horton)

export const UNKNOWN = 'Unknown';
const UNDER_1M = '< 1m';
const BETWEEN_1M_30M = '1m to 30m';
const BETWEEN_30M_100M = '30m to 100m';
const OVER_100M = '100m+';
const UNDER_50M_YEN = '< 50m yen';
const BETWEEN_50M_100M_YEN = '50m to 100m yen';
const BETWEEN_100M_1B_YEN = '100m to 1b yen';
const BETWEEN_1B_5B_YEN = '1b to 5b yen';
const BETWEEN_5B_10B_YEN = '5b to 10b yen';
const BETWEEN_10B_50B_YEN = '10b to 50b yen';
const BETWEEN_50B_100B_YEN = '50b to 100b yen';
const BETWEEN_100B_500B_YEN = '100b to 500b yen';
const BETWEEN_500B_1T_YEN = '500b to 1t yen';
const OVER_1T_YEN = '> 1t yen';

/**
 * @deprecated Revenue ranges should not be used directly. Render <AnnualOnlineRevenueRangeSelect /> instead
 */
export function getAnnualRevenueRanges(locale: string) {
  switch (locale) {
    case 'de':
      return [
        {label: 'Eine Option auswählen', value: ''},
        {label: 'Unbekannt', value: UNKNOWN},
        {label: 'Bis zu 1 Mio. Dollar', value: UNDER_1M},
        {label: '1 bis 30 Mio. Dollar', value: BETWEEN_1M_30M},
        {label: '30 bis 100 Mio. Dollar', value: BETWEEN_30M_100M},
        {label: 'Mehr als 100 Mio. Dollar', value: OVER_100M},
      ];
    case 'es':
    case 'es-ES':
      return [
        {label: 'Selecciona uno', value: ''},
        {label: 'Desconocido', value: UNKNOWN},
        {label: 'Hasta 1 millón de dólares', value: UNDER_1M},
        {label: 'De 1 a 30 millones de dólares', value: BETWEEN_1M_30M},
        {label: 'De 30 a 100 millones de dólares', value: BETWEEN_30M_100M},
        {label: 'Más de 100 millones de dólares', value: OVER_100M},
      ];
    case 'fr':
    case 'fr-CA':
      return [
        {label: 'Sélectionnez une option', value: ''},
        {label: 'Inconnu', value: UNKNOWN},
        {label: 'Jusqu’à 1 million de dollars', value: UNDER_1M},
        {label: '1 à 30 millions de dollars', value: BETWEEN_1M_30M},
        {label: '30 à 100 millions de dollars', value: BETWEEN_30M_100M},
        {label: 'Plus de 100 millions de dollars', value: OVER_100M},
      ];
    case 'it':
      return [
        {label: 'Seleziona un’opzione', value: ''},
        {label: 'Non so', value: UNKNOWN},
        {label: 'Fino a 1 milione di euro', value: UNDER_1M},
        {label: '1-30 milioni di euro', value: BETWEEN_1M_30M},
        {label: '30-100 milioni di euro', value: BETWEEN_30M_100M},
        {label: 'Oltre 100 milioni di euro', value: OVER_100M},
      ];
    case 'ja':
      return [
        {label: '5,000万円未満', value: UNDER_50M_YEN},
        {label: '5,000万円～1億円未満', value: BETWEEN_50M_100M_YEN},
        {label: '1 億円～10億円未満', value: BETWEEN_100M_1B_YEN},
        {label: '10億円～50億円', value: BETWEEN_1B_5B_YEN},
        {label: '50億円～100億円', value: BETWEEN_5B_10B_YEN},
        {label: '100億円～500億円', value: BETWEEN_10B_50B_YEN},
        {label: '500億円～1,000億円', value: BETWEEN_50B_100B_YEN},
        {label: '1,000億円～5,000億円', value: BETWEEN_100B_500B_YEN},
        {label: '5,000億円～1兆円', value: BETWEEN_500B_1T_YEN},
        {label: '1兆円～', value: OVER_1T_YEN},
        {label: '該当なし', value: UNKNOWN},
      ];
    case 'zh-CN':
      return [
        {label: '单选', value: ''},
        {label: '不知道', value: UNKNOWN},
        {label: '100 万美元及以下', value: UNDER_1M},
        {label: '100 万 - 3000 万美元', value: BETWEEN_1M_30M},
        {label: '3000 万 - 1 亿美元', value: BETWEEN_30M_100M},
        {label: '1 亿美元以上', value: OVER_100M},
      ];
    default:
      return [
        {label: 'Select one', value: ''},
        {label: 'Unknown', value: UNKNOWN},
        {label: 'Up to 1 million dollars', value: UNDER_1M},
        {label: '1 to 30 million dollars', value: BETWEEN_1M_30M},
        {label: '30 to 100 million dollars', value: BETWEEN_30M_100M},
        {label: 'Over 100 million dollars', value: OVER_100M},
      ];
  }
}
