import {useEffect, useState} from 'react';
import cn from 'classnames';

import Contact from '@/components/plus/section/ContactForm/Contact';
import Modal, {ModalColorScheme} from '@/components/shared/Modal/Modal';
import {ModalSizeEnum} from '@/enums';
import {removeHash, usePushState} from '@/hooks/usePushState';

export default function ContactModal() {
  const {targetMatched} = usePushState({targetHash: '#contact-sales'});
  const [animationState, setAnimationState] = useState<
    'opening' | 'closing' | 'closed'
  >('closed');

  const modalId = 'contact-modal';

  useEffect(() => {
    if (targetMatched) {
      setAnimationState('opening');
    }
  }, [targetMatched]);

  const handleTransitionEnd = (event: TransitionEvent) => {
    const target = event.target as HTMLElement;
    if (animationState === 'closing' && target.id === modalId) {
      removeHash();
      setAnimationState('closed');
    }
  };

  const handleClose = () => {
    setAnimationState('closing');
  };

  return (
    <Modal
      handleClose={handleClose}
      // @ts-ignore
      onTransitionEnd={handleTransitionEnd}
      id={modalId}
      isActive={targetMatched}
      modalSize={ModalSizeEnum.Full}
      colorScheme={ModalColorScheme.Dark}
      className={cn(
        'transition-all duration-[450ms] ease-in-out bg-section-secondary-bg contact-form-gradient contact-form-noise',
        {
          'opacity-100 rounded-none': animationState === 'opening',
          'opacity-0 scale-95 rounded-2xl':
            animationState === 'closing' || animationState === 'closed',
        },
      )}
    >
      <Contact isPlus={false} />
    </Modal>
  );
}
