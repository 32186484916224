import {
  ShopifyDarkLogo,
  ShopifyWhiteLogo,
} from '@/pages/shopify.com/($locale)/enterprise/components/Header/ShopifyLogo';
import {useTranslations} from '@/components/shared/Page/I18N';

interface ShopifyLogoProps {
  isLightBackground: boolean;
  isOpenMenu: boolean;
}

export default function ShopifyLogo({
  isLightBackground,
  isOpenMenu,
}: ShopifyLogoProps) {
  const {t, localizePath} = useTranslations();
  return (
    <div className="lg:w-[125px] mr-12 w-[98px] shrink-0">
      <a
        href={localizePath('/enterprise')}
        data-component-name="logo-home"
        aria-label={t('global:ariaLabels.shopifyLogo')}
      >
        {isLightBackground || isOpenMenu ? (
          <ShopifyDarkLogo />
        ) : (
          <ShopifyWhiteLogo />
        )}
      </a>
    </div>
  );
}
