export const ShopifyWhiteLogo = () => (
  <svg
    width="127"
    height="36"
    viewBox="0 0 127 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_596_25572)">
      <path
        d="M43.7848 20.0186C42.7072 19.4257 42.1401 18.9457 42.1401 18.2681C42.1401 17.3928 42.9057 16.8563 44.1251 16.8563C45.543 16.8563 46.7908 17.4492 46.7908 17.4492L47.7833 14.4281C47.7833 14.4281 46.8759 13.7222 44.1818 13.7222C40.4386 13.7222 37.8296 15.8681 37.8296 18.861C37.8296 20.5551 39.049 21.8539 40.6654 22.7857C41.9699 23.5198 42.4236 24.0563 42.4236 24.8186C42.4236 25.6375 41.7714 26.2869 40.552 26.2869C38.7371 26.2869 37.0356 25.3551 37.0356 25.3551L35.9863 28.3763C35.9863 28.3763 37.5744 29.421 40.2117 29.421C44.0684 29.421 46.8192 27.5292 46.8192 24.141C46.8759 22.2775 45.458 20.9786 43.7848 20.0186Z"
        fill="white"
      />
      <path
        d="M59.1549 13.6659C57.2549 13.6659 55.7803 14.5695 54.6176 15.9248L54.5609 15.8965L56.2057 7.31299H51.8952L47.7266 29.1389H52.0086L53.4266 21.6848C53.9937 18.8612 55.44 17.1389 56.8012 17.1389C57.7654 17.1389 58.134 17.7883 58.134 18.72C58.134 19.313 58.0773 20.0189 57.9355 20.6118L56.3191 29.1671H60.6012L62.2743 20.3577C62.4728 19.4259 62.5862 18.3248 62.5862 17.5624C62.643 15.1059 61.3952 13.6659 59.1549 13.6659Z"
        fill="white"
      />
      <path
        d="M72.3415 13.6658C67.1804 13.6658 63.749 18.2964 63.749 23.4634C63.749 26.7669 65.7908 29.4493 69.6475 29.4493C74.7236 29.4493 78.1266 24.9317 78.1266 19.6517C78.155 16.574 76.3684 13.6658 72.3415 13.6658ZM70.243 26.1458C68.7684 26.1458 68.1729 24.9034 68.1729 23.3505C68.1729 20.894 69.449 16.9128 71.7744 16.9128C73.3057 16.9128 73.7878 18.2117 73.7878 19.4822C73.7878 22.1364 72.5117 26.1458 70.243 26.1458Z"
        fill="white"
      />
      <path
        d="M89.1293 13.6658C86.2368 13.6658 84.592 16.2069 84.592 16.2069H84.5353L84.7905 13.9199H80.9905C80.792 15.4728 80.4517 17.8164 80.1114 19.5952L77.1338 35.1811H81.4159L82.6069 28.8564H82.692C82.692 28.8564 83.5711 29.4211 85.2159 29.4211C90.2636 29.4211 93.5532 24.2822 93.5532 19.0869C93.5532 16.2352 92.2771 13.6658 89.1293 13.6658ZM85.0457 26.2305C83.9397 26.2305 83.2875 25.6093 83.2875 25.6093L83.9965 21.6281C84.5069 18.974 85.8965 17.1952 87.3711 17.1952C88.6756 17.1952 89.0726 18.4093 89.0726 19.5387C89.0726 22.2775 87.4278 26.2305 85.0457 26.2305Z"
        fill="white"
      />
      <path
        d="M99.6786 7.53882C98.3174 7.53882 97.2114 8.61176 97.2114 10.0235C97.2114 11.2941 98.0055 12.1694 99.2249 12.1694H99.2816C100.614 12.1694 101.777 11.2659 101.805 9.6847C101.834 8.41411 100.983 7.53882 99.6786 7.53882Z"
        fill="white"
      />
      <path
        d="M93.667 29.1388H97.9774L100.898 14.0046H96.5595L93.667 29.1388Z"
        fill="white"
      />
      <path
        d="M111.788 13.9764H108.81L108.952 13.2705C109.207 11.8023 110.058 10.5035 111.504 10.5035C112.27 10.5035 112.865 10.7293 112.865 10.7293L113.716 7.39758C113.716 7.39758 112.979 7.03052 111.391 7.03052C109.86 7.03052 108.357 7.45405 107.194 8.44228C105.719 9.68464 105.039 11.4635 104.698 13.2705L104.585 13.9764H102.6L101.976 17.1952H103.961L101.692 29.1388H105.974L108.243 17.1952H111.192L111.788 13.9764Z"
        fill="white"
      />
      <path
        d="M122.11 14.0046C122.11 14.0046 119.416 20.7246 118.225 24.3952H118.168C118.083 23.2093 117.119 14.0046 117.119 14.0046H112.61L115.191 27.8964C115.247 28.207 115.219 28.4046 115.105 28.6023C114.595 29.5623 113.773 30.4941 112.78 31.1717C111.986 31.7646 111.079 32.1317 110.37 32.3858L111.561 35.9999C112.44 35.8023 114.226 35.0964 115.758 33.6846C117.714 31.8493 119.529 29.0541 121.373 25.2141L126.619 14.0046H122.11Z"
        fill="white"
      />
      <path
        d="M21.2118 4.1788C21.2118 4.1788 20.8148 4.29174 20.1625 4.48939C20.0491 4.12233 19.879 3.6988 19.6521 3.24704C18.9148 1.83527 17.8088 1.07292 16.5043 1.07292C16.4193 1.07292 16.3342 1.07292 16.2208 1.10116C16.1924 1.04469 16.1357 1.01645 16.1073 0.95998C15.5402 0.338804 14.8028 0.0564509 13.9237 0.0846862C12.2223 0.141157 10.5208 1.35527 9.15957 3.52939C8.1954 5.0541 7.45808 6.9741 7.25958 8.47057C5.30286 9.06351 3.94167 9.48704 3.91331 9.51527C2.92078 9.82586 2.89242 9.8541 2.77899 10.7859C2.69391 11.4917 0.0849609 31.3694 0.0849609 31.3694L21.5237 35.0682V4.15057C21.3536 4.15057 21.2685 4.1788 21.2118 4.1788ZM16.2491 5.70351C15.1148 6.04233 13.867 6.43763 12.6476 6.80469C12.9879 5.47763 13.6685 4.15057 14.4626 3.27527C14.7745 2.96469 15.1999 2.59763 15.682 2.37174C16.164 3.38822 16.2775 4.77174 16.2491 5.70351ZM13.9237 1.24233C14.3208 1.24233 14.6611 1.32704 14.9446 1.49645C14.4909 1.72233 14.0372 2.08939 13.6118 2.51292C12.5342 3.67057 11.7118 5.47763 11.3715 7.19998C10.3506 7.51057 9.32972 7.82116 8.3939 8.10351C9.01778 5.39292 11.3148 1.2988 13.9237 1.24233ZM10.6342 16.7717C10.7476 18.5788 15.5402 18.9741 15.8237 23.2376C16.0223 26.5976 14.0372 28.8847 11.173 29.0541C7.71331 29.28 5.81331 27.247 5.81331 27.247L6.55062 24.1412C6.55062 24.1412 8.45062 25.5812 9.98196 25.4682C10.9745 25.4117 11.3432 24.5929 11.3148 24.0282C11.173 21.6564 7.25958 21.7976 7.00435 17.9012C6.77749 14.6259 8.93271 11.3223 13.6969 11.0117C15.5402 10.8988 16.476 11.3506 16.476 11.3506L15.3984 15.4164C15.3984 15.4164 14.179 14.8517 12.7327 14.9647C10.6342 15.1059 10.6058 16.4329 10.6342 16.7717ZM17.3834 5.36469C17.3834 4.51763 17.27 3.30351 16.873 2.28704C18.1775 2.54116 18.8014 3.98116 19.0849 4.85645C18.5745 4.99763 18.0073 5.16704 17.3834 5.36469Z"
        fill="white"
      />
      <path
        d="M22.1758 35.0119L31.0802 32.8095C31.0802 32.8095 27.2519 7.03068 27.2235 6.86127C27.1952 6.69185 27.0534 6.57891 26.9116 6.57891C26.7698 6.57891 24.2743 6.52244 24.2743 6.52244C24.2743 6.52244 22.7429 5.05421 22.1758 4.4895V35.0119Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_596_25572">
        <rect width="126.562" height="36" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const ShopifyDarkLogo = () => (
  <svg
    width="127"
    height="36"
    viewBox="0 0 127 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_6000_43837)">
      <path
        d="M27.1856 6.83191C27.1603 6.65544 27.0084 6.5546 26.8819 6.5546C26.7553 6.5546 24.2494 6.50418 24.2494 6.50418C24.2494 6.50418 22.1484 4.48738 21.9459 4.26048C21.7434 4.0588 21.3384 4.10922 21.1866 4.15964C21.1866 4.15964 20.7816 4.28569 20.1234 4.48738C20.0222 4.13443 19.845 3.70586 19.6172 3.25208C18.8831 1.84032 17.7694 1.08401 16.4531 1.08401C16.3519 1.08401 16.2759 1.08401 16.1747 1.10922C16.1241 1.0588 16.0988 1.00838 16.0481 0.983174C15.4659 0.378132 14.7319 0.075611 13.8459 0.100821C12.15 0.151241 10.4541 1.38654 9.06188 3.5546C8.1 5.09242 7.36594 7.00838 7.13813 8.49578C5.18906 9.10082 3.82219 9.52939 3.77156 9.52939C2.78437 9.83191 2.75906 9.85712 2.6325 10.7899C2.58188 11.4958 0 31.3865 0 31.3865L21.6675 35.1176L31.0584 32.7983C31.0331 32.7983 27.2109 7.00838 27.1856 6.83191ZM19.035 4.84032C18.5287 4.99158 17.9719 5.16805 17.3644 5.36973C17.3644 4.51259 17.2378 3.3025 16.8581 2.2941C18.1491 2.49578 18.7819 3.95796 19.035 4.84032ZM16.2253 5.69746C15.0863 6.0504 13.8459 6.42855 12.6056 6.8067C12.96 5.47057 13.6181 4.15964 14.4281 3.27729C14.7319 2.94956 15.1622 2.59662 15.6431 2.39494C16.1494 3.37813 16.2506 4.76469 16.2253 5.69746ZM13.8966 1.23527C14.3016 1.23527 14.6306 1.31091 14.9091 1.51259C14.4534 1.73948 13.9978 2.09242 13.5928 2.5462C12.5044 3.70586 11.6691 5.49578 11.34 7.23527C10.3022 7.56301 9.28969 7.86553 8.37844 8.14284C8.98594 5.39494 11.2894 1.31091 13.8966 1.23527Z"
        fill="black"
      />
      <path
        d="M26.8823 6.55469C26.7558 6.55469 24.2498 6.50427 24.2498 6.50427C24.2498 6.50427 22.1489 4.48746 21.9464 4.26057C21.8705 4.18494 21.7692 4.13452 21.668 4.13452V35.1177L31.0589 32.7984C31.0589 32.7984 27.2367 7.00847 27.2114 6.832C27.1608 6.65553 27.0089 6.55469 26.8823 6.55469Z"
        fill="black"
      />
      <path
        d="M16.4534 11.3699L15.3649 15.4287C15.3649 15.4287 14.1499 14.8741 12.7071 14.975C10.5809 15.101 10.5809 16.4372 10.5809 16.7649C10.7074 18.58 15.4915 18.9834 15.7699 23.2439C15.9724 26.5968 13.998 28.8909 11.1124 29.0674C7.66992 29.2439 5.77148 27.2271 5.77148 27.2271L6.50555 24.1262C6.50555 24.1262 8.4293 25.5632 9.94805 25.4624C10.9352 25.4119 11.3149 24.58 11.2643 24.0254C11.1124 21.6556 7.2143 21.8069 6.96117 17.8993C6.75867 14.622 8.91023 11.3195 13.669 11.017C15.5168 10.8909 16.4534 11.3699 16.4534 11.3699Z"
        fill="white"
      />
      <path
        d="M43.7651 20.0167C42.6766 19.4369 42.1198 18.9327 42.1198 18.252C42.1198 17.3948 42.9045 16.8402 44.1195 16.8402C45.537 16.8402 46.8026 17.4201 46.8026 17.4201L47.7898 14.3948C47.7898 14.3948 46.8785 13.689 44.1954 13.689C40.4491 13.689 37.842 15.8318 37.842 18.8318C37.842 20.5461 39.057 21.8318 40.677 22.7646C41.9932 23.4957 42.4488 24.0251 42.4488 24.8066C42.4488 25.6133 41.7907 26.2688 40.5757 26.2688C38.7785 26.2688 37.0573 25.336 37.0573 25.336L35.9941 28.3612C35.9941 28.3612 37.5635 29.4201 40.2213 29.4201C44.0688 29.4201 46.8532 27.5293 46.8532 24.1259C46.8279 22.2856 45.4357 20.9747 43.7651 20.0167Z"
        fill="black"
      />
      <path
        d="M59.1035 13.6385C57.2051 13.6385 55.7116 14.5461 54.5726 15.9074L54.5219 15.8822L56.1673 7.31079H51.8895L47.7129 29.1427H51.9907L53.4082 21.6805C53.9651 18.857 55.4332 17.1175 56.8001 17.1175C57.7619 17.1175 58.1416 17.773 58.1416 18.7057C58.1416 19.2856 58.091 20.0167 57.9644 20.5965L56.3445 29.1427H60.6223L62.2929 20.3192C62.4701 19.3864 62.5966 18.2772 62.5966 17.5209C62.6219 15.1007 61.3563 13.6385 59.1035 13.6385Z"
        fill="black"
      />
      <path
        d="M72.3173 13.6387C67.1535 13.6387 63.7363 18.2773 63.7363 23.4454C63.7363 26.7479 65.7866 29.4202 69.6341 29.4202C74.6967 29.4202 78.1138 24.9076 78.1138 19.6135C78.1392 16.563 76.342 13.6387 72.3173 13.6387ZM70.2163 26.1681C68.7482 26.1681 68.1407 24.9328 68.1407 23.3698C68.1407 20.9244 69.4063 16.9412 71.7351 16.9412C73.2538 16.9412 73.7601 18.2521 73.7601 19.5126C73.7601 22.1345 72.4691 26.1681 70.2163 26.1681Z"
        fill="black"
      />
      <path
        d="M89.0998 13.6387C86.2141 13.6387 84.5688 16.1849 84.5688 16.1849H84.5182L84.7713 13.8908H80.9744C80.7973 15.4286 80.4429 17.7983 80.1138 19.563L77.127 35.1933H81.4048L82.5944 28.8656H82.6957C82.6957 28.8656 83.5816 29.4202 85.2016 29.4202C90.2388 29.4202 93.5294 24.2773 93.5294 19.0841C93.5294 16.2101 92.2385 13.6387 89.0998 13.6387ZM84.9991 26.2185C83.8854 26.2185 83.2273 25.5883 83.2273 25.5883L83.936 21.6051C84.4423 18.9328 85.8344 17.1681 87.3279 17.1681C88.6441 17.1681 89.0491 18.3782 89.0491 19.5126C89.0491 22.2857 87.4038 26.2185 84.9991 26.2185Z"
        fill="black"
      />
      <path
        d="M99.6545 7.51245C98.2877 7.51245 97.1992 8.59648 97.1992 9.98304C97.1992 11.2435 98.0092 12.1259 99.2242 12.1259H99.2748C100.616 12.1259 101.755 11.2183 101.781 9.65531C101.781 8.42001 100.945 7.51245 99.6545 7.51245Z"
        fill="black"
      />
      <path
        d="M93.6562 29.1175H97.9341L100.845 13.9915H96.5419L93.6562 29.1175Z"
        fill="black"
      />
      <path
        d="M111.754 13.9663H108.767L108.919 13.2604C109.172 11.7982 110.033 10.5125 111.475 10.5125C112.235 10.5125 112.842 10.7394 112.842 10.7394L113.678 7.38645C113.678 7.38645 112.944 7.0083 111.349 7.0083C109.83 7.0083 108.311 7.43687 107.147 8.42007C105.679 9.65536 104.995 11.4453 104.666 13.2604L104.54 13.9663H102.54L101.907 17.1932H103.907L101.629 29.1428H105.907L108.185 17.1932H111.146L111.754 13.9663Z"
        fill="black"
      />
      <path
        d="M122.083 13.9915C122.083 13.9915 119.4 20.7225 118.21 24.4032H118.159C118.083 23.2183 117.096 13.9915 117.096 13.9915H112.591L115.173 27.8822C115.223 28.1847 115.198 28.3864 115.071 28.5881C114.565 29.5461 113.73 30.4789 112.743 31.1595C111.933 31.7394 111.021 32.1175 110.312 32.3696L111.502 35.9999C112.363 35.8234 114.185 35.0923 115.704 33.6805C117.653 31.8654 119.476 29.0419 121.323 25.2099L126.563 13.9915H122.083Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_6000_43837">
        <rect width="126.562" height="36" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
