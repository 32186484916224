import {type HydratedT} from '@/components/shared/Page/I18N';
import type {LinkComponent} from '@/types';
import type {Topics} from '@/pages/shopify.com/($locale)/enterprise/blog/types';

export function getSubNavLinks(
  t: HydratedT,
  localizePath: (path: string) => string,
  enBlogPath: string,
  topics: Topics[],
) {
  const links = topics?.map((topic) => ({
    text: topic?.title,
    url: localizePath(`/enterprise/blog/topics/${topic?.handle}`),
    name: topic?.handle,
  }));

  return () => ({
    sectionTitle: t('subNav.title'),
    sectionOverviewUrl: localizePath(enBlogPath),
    pageTitle: t('subNav.pageTitle'),
    overviewText: t('subNav.overviewText'),
    links: [
      ...links?.slice(0, 4),
      {
        text: t('subNav.links.moreTopics'),
        name: 'moreTopics',
        links: [
          ...links?.slice(4, links.length),
          {
            text: t('subNav.subLinks.see-all-topics'),
            url: localizePath('/enterprise/blog/topics'),
            name: 'see-all-topics',
          },
        ],
      },
    ] as LinkComponent[],
  });
}
