import type {SerializeFrom} from '@remix-run/server-runtime';
import {useEffect, useState} from 'react';

import {useLoadScript} from './useLoadScript';

type LDBookit = Window &
  typeof globalThis & {
    LDBookItV2: {
      initialize: (
        account: string,
        type: string,
        hiddenField: string,
        options: {},
      ) => void;
      setFormTarget: (form: HTMLElement | null) => void;
      setFormProvider: (provider: string) => void;
      submit: (obj: {[key: string]: any}) => void;
    };
  };

const useBookIt = (
  data: SerializeFrom<any>,
  formID: string,
  redirectPath?: string,
  debug?: boolean,
) => {
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const scriptStatus = useLoadScript(
    'https://cdn.leandata.com/js-snippet/ld-book-v2.js',
  );

  const redirectUrl = redirectPath ? window.location.origin + redirectPath : '';

  useEffect(() => {
    if (scriptStatus === 'done') {
      setScriptLoaded(true);
    }
  }, [scriptStatus]);

  useEffect(() => {
    if (debug) {
      window.localStorage.setItem('_LD_debugOn', 'true');
    }
  });

  useEffect(() => {
    if (scriptLoaded) {
      const myForm = document.getElementById(formID);
      const bookIt = window as LDBookit;
      bookIt.LDBookItV2.initialize(
        '00D8V0000028tSkUAI',
        'New Prospect',
        'ld_bookit_log_id',
        {
          autoSubmit: false,
          popupModalCloseURL: redirectUrl,
          hideLoadingScreen: true,
        },
      );
      bookIt.LDBookItV2.setFormTarget(myForm);
      bookIt.LDBookItV2.setFormProvider('custom');
    }
  }, [formID, scriptLoaded, redirectUrl]);

  useEffect(() => {
    const myForm = document.getElementById(formID);
    const bookIt = window as LDBookit;
    const BookItAPIRouting = (form: HTMLElement | null) => {
      if (form && scriptLoaded) {
        bookIt.LDBookItV2.submit({
          formData: data.payload,
        });
      }
    };

    if (data && data.payload && (data.success || data.status === 'success')) {
      BookItAPIRouting(myForm);
    }
  }, [data, formID, scriptLoaded]);
};

export default useBookIt;
