import type {Handle, I18NOptions} from '@/types';
import ContactModal from '@/pages/shopify.com/($locale)/enterprise/blog/components/ContactForm/Modal';

import {ENTERPRISE_SIGNUP_URL} from './constants';

const driftBotLocales = new Set(['en', 'es', 'de', 'ja', 'it', 'fr', 'zh-CN']);

export const enterpriseHandle: Handle = {
  globalNav({t}: I18NOptions) {
    return {
      signupText: t('nav:getInTouch'),
    };
  },
  signupUrl: ENTERPRISE_SIGNUP_URL,
  CustomModal: ContactModal,
  driftBot({site}) {
    const localePrefix = site.locale.split('-')[0];
    return (
      driftBotLocales.has(site.locale) || driftBotLocales.has(localePrefix)
    );
  },
};
