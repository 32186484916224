import * as React from 'react';

import Header from '@/pages/shopify.com/($locale)/enterprise/components/Header/Header';
import {HeaderBackgroundColorScheme} from '@/enums';
import {useSharedTranslations, useTranslations} from '@/hooks/useTranslations';
import {NavigationSearch} from '@/components/pages/blog/NavigationSearch/NavigationSearch';
import type {Topics} from '@/pages/shopify.com/($locale)/enterprise/blog/types';
import {BLOG_PAGE_TYPE} from '@/components/blog/types';
import {useSiteData} from '@/hooks/useSiteData';
import ShopifyLogo from '@/pages/shopify.com/($locale)/enterprise/components/Header/ShopifyLogoClient';

import {getSubNavLinks} from './getSubNavLinks';

interface BlogSubNavProps {
  enBlogPath: string;
  subNavTopics: Topics[];
}

export function BlogSubNav({enBlogPath, subNavTopics}: BlogSubNavProps) {
  const {t: sharedT} = useSharedTranslations(`pages${enBlogPath}blogSubNav`);
  const {t, localizePath} = useTranslations();
  const {enPath} = useSiteData();

  const secondaryNavFn = React.useMemo(() => {
    return getSubNavLinks(sharedT, localizePath, enBlogPath, subNavTopics);
  }, [enBlogPath, localizePath, sharedT, subNavTopics]);
  const isHomePage = `${enPath}/` === enBlogPath;

  return (
    <Header
      logo={ShopifyLogo}
      background={HeaderBackgroundColorScheme.TransparentDark}
      navigationSearch={
        <NavigationSearch
          blogType={BLOG_PAGE_TYPE.ENTERPRISE}
          enBlogPath={enBlogPath}
          searchText={t('global:globalNav.searchText')}
          searchPlaceholder={t('global:globalNav.searchPlaceholder')}
          searchPlaceholderMobile={t(
            'global:globalNav.searchPlaceholderMobile',
          )}
        />
      }
      secondaryNavFn={secondaryNavFn}
      className={`blog-subnav ${isHomePage ? 'dark-bg' : ''}`}
      blogType={BLOG_PAGE_TYPE.ENTERPRISE}
      ctaLink={sharedT('ctaLink')}
    />
  );
}
